import Client from "@/lib/http-client";
import type {
  SpecialResponseModelType,
  GeneralResponseModelType,
} from "@/shared/types/response.types";

import type {
  FetchResourcesParamsType,
  ResourceModelType,
  ResourceTypeModelType,
  FetchResourceTypesParamsType,
} from "./resources.types";

const fetchResourcesList = async (
  params?: FetchResourcesParamsType,
  signal?: AbortSignal
): Promise<SpecialResponseModelType<ResourceModelType[]>> => {
  return Client.get("/pdf-resources/", params, signal);
};

const fetchResourceTypes = async (
  params?: FetchResourceTypesParamsType
): Promise<GeneralResponseModelType<ResourceTypeModelType[]>> => {
  return Client.get("/resource-types", params);
};

const methods = { fetchResourcesList, fetchResourceTypes };

export default methods;
