import { Typography } from "@mui/material";
import { ResourceModelType } from "@/shared/api/resources";
import { ResourceCard } from "@/shared/components";
import colors from "@/lib/palette";

const ResourcesList = ({ resources }: { resources: ResourceModelType[] }) => {
  if (!resources.length) {
    return (
      <Typography
        component="span"
        variant="h3"
        sx={{
          color: colors.black,
          marginTop: "40px",
          textAlign: "center",
          gridColumn: "1 / -1",
        }}
      >
        No results were found. Please try another search
      </Typography>
    );
  }

  return resources.map((resource) => (
    <ResourceCard key={resource.id} resource={resource} />
  ));
};

export default ResourcesList;
