"use client";
import React from "react";

import {
  Typography,
  Checkbox,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Select,
  FormControl,
  type SxProps,
  type SelectChangeEvent,
} from "@mui/material";

import colors from "@/lib/palette";

import { ResourceTypeModelType } from "@/shared/api/resources";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      transform: "translateX(-70px)",
    },
  },
};

type ComboFilterProps = {
  value: string[];
  types: ResourceTypeModelType[];
  onSelect: (filter: string, value: string) => void;
  sx?: SxProps;
  selectSx?: SxProps;
};

export default function ResourceTypesDropdownFeature({
  value,
  types,
  selectSx,
  sx,
  onSelect,
}: ComboFilterProps) {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    onSelect(
      "resourceType",
      typeof value === "string" ? value : value.join(",")
    );
  };

  return (
    <FormControl
      sx={{
        display: "block",
        ...sx,
      }}
    >
      <Select
        multiple
        displayEmpty
        value={value || []}
        onChange={handleChange}
        input={<OutlinedInput label="Keyword" />}
        renderValue={(selected) => {
          return (
            <Typography variant="subtitle1">
              Type
              {selected.length > 0 ? ` (${selected.length})` : ""}
            </Typography>
          );
        }}
        MenuProps={MenuProps}
        sx={{
          height: "48px",
          backgroundColor: colors.white,
          borderRadius: 2,
          "& fieldset": {
            top: 0,
            borderColor: `${colors.steel} !important`,
            borderWidth: "1px !important",
            "& legend": {
              display: "none",
            },
          },

          ...selectSx,
        }}
      >
        {types.map((tag) => (
          <MenuItem key={tag.value} value={tag.value}>
            <Checkbox checked={value.includes(tag.value as string)} />
            <ListItemText
              primary={`${tag.name} ${
                tag.resources_count ? `(${tag.resources_count})` : ""
              }`}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
