import ResourceApi from "./resources.api";

import type {
  ResourceModelType,
  FetchResourcesParamsType,
  ResourceCategoryModelType,
  ResourceCompanyModelType,
  ResourcePDFImageModelType,
  FetchResourceTypesParamsType, 
  ResourceTypeModelType,
} from "./resources.types";

export type {
  ResourceModelType,
  FetchResourcesParamsType,
  ResourceCategoryModelType,
  ResourceCompanyModelType,
  ResourcePDFImageModelType,
  FetchResourceTypesParamsType,
  ResourceTypeModelType,
};

export default ResourceApi;
