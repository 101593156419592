import KeywordsApi from "./keywords.api";

import type {
  FetchKeywordsParamsType,
  KeywordModelType,
} from "./keywords.types";

export type { FetchKeywordsParamsType, KeywordModelType };

export default KeywordsApi;
