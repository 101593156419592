"use client";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { SxProps } from "@mui/material";
import { Dropdown } from "@/shared/components";
import KeywordsApi, {
  type FetchKeywordsParamsType,
  KeywordModelType,
} from "@/shared/api/keywords";

type Props = {
  value: string[];
  clear?: boolean;
  sx?: SxProps;
  onSelect: (value: string[]) => void;
  renderSelectedItems?: (
    value: string[],
    handleSelect: (name: string) => void
  ) => JSX.Element;
};

const initialState = {
  keywords: [] as KeywordModelType[],
  loading: false,
  intersectLoading: false,
  hasMore: true,
  params: {
    query: "",
    page: 1,
    pageCount: 12,
  } as FetchKeywordsParamsType,
};

function reducer(
  state: typeof initialState,
  action: {
    type:
      | "SET_KEYWORDS"
      | "SET_LOADING"
      | "SET_INTERSECT_LOADING"
      | "SET_PARAMS";

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any;
  }
) {
  switch (action.type) {
    case "SET_KEYWORDS":
      return {
        ...state,
        keywords:
          state.params.page === 1
            ? action.payload
            : [...state.keywords, ...action.payload],
        hasMore: action.payload.length === state.params.pageCount,
      };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_INTERSECT_LOADING":
      return { ...state, intersectLoading: action.payload };
    case "SET_PARAMS":
      return { ...state, params: { ...state.params, ...action.payload } };
    default:
      return state;
  }
}

const KeywordsSearchingFeature = ({
  value,
  sx,
  clear,
  onSelect,
  renderSelectedItems,
}: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedParams = useMemo(
    () => ({
      query: state.params.query,
      page: state.params.page,
      pageCount: state.params.pageCount,
    }),
    [state.params.query, state.params.page, state.params.pageCount]
  );

  const handleSearchKeywords = useCallback((query: string) => {
    dispatch({ type: "SET_PARAMS", payload: { query, page: 1 } });
    dispatch({ type: "SET_INTERSECT_LOADING", payload: false });
  }, []);

  const handleIntersectKeywords = useCallback(() => {
    if (!state.hasMore || state.loading || state.intersectLoading) return;

    dispatch({ type: "SET_INTERSECT_LOADING", payload: true });
    dispatch({ type: "SET_PARAMS", payload: { page: state.params.page + 1 } });
  }, [state.hasMore, state.loading, state.intersectLoading, state.params.page]);

  const fetchKeywords = useCallback(
    async (signal?: AbortSignal) => {
      if (!state.intersectLoading) {
        dispatch({ type: "SET_LOADING", payload: true });
      }

      try {
        const { data } = await KeywordsApi.fetchKeywordsList(
          memoizedParams,
          signal
        );

        dispatch({ type: "SET_KEYWORDS", payload: data });
      } catch (error) {
        if (error instanceof Error && error.message === "Request was aborted") {
          return;
        }
        console.error(error);
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
        dispatch({ type: "SET_INTERSECT_LOADING", payload: false });
      }
    },
    [memoizedParams]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchKeywords(signal);

    return () => {
      controller.abort();
    };
  }, [memoizedParams, fetchKeywords]);

  useEffect(() => {
    if (clear) {
      dispatch({ type: "SET_PARAMS", payload: { query: "", page: 1 } });
    }
  }, [clear]);

  return (
    <Dropdown
      value={value}
      searchValue={state.params.query}
      items={state?.keywords || []}
      loading={state.loading}
      searchPlaceholder="Search for keywords"
      listLoading={state.intersectLoading}
      onSearch={handleSearchKeywords}
      onSelect={onSelect}
      onIntersect={handleIntersectKeywords}
      renderSelectedItems={renderSelectedItems}
      sx={sx}
    />
  );
};

export default KeywordsSearchingFeature;
